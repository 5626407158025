'use strict'
require("babel-polyfill");
// var colorify = require('colorify.js');
import 'objectFitPolyfill';
import 'url-polyfill'
import 'nodelist-foreach-polyfill'
import $ from "expose-loader?exposes=$,jQuery!jquery";
// import 'hammerjs'
// import 'custom-event-polyfill'

// import Swiper from 'swiper/bundle'
 
import SmoothScroll from './library/_SmoothScroll.js'
import ScrollHelper from './library/_ScrollHelper.js'
import ResizeHelper from './library/_ResizeHelper.js'
import ScrollTrigger from '@terwanerik/scrolltrigger'
// import ScrollDirection from './_ScrollDirection.js'
// import ResponsiveSwiper from './_ResponsiveSwiper.js'
// import Intersection from './_Intersection.js'
const TRANSITION_END = 'webkitTransitionEnd transitionend';



$(() => {
  const $window = $(window);
  const $body = $('body');
  const $header = $('.js_header')
  $body.addClass('loaded');


  // ---------------------------------------------------------------
  // scroll effect 
  // ---------------------------------------------------------------
  let windowHeight = window.innerHeight;
  const target = $('body')
  if ( target.length ) {
    let scrolltargetPos = target.length ? target.offset().top + target.outerHeight() : 0
    const resizeHelper = new ResizeHelper(() => {
      windowHeight = window.innerHeight;
      scrolltargetPos = target.offset().top + target.height()
    })
    const scrolled = new ScrollHelper(function(){
      const scrolltop = $(window).scrollTop()
      const _windowHeight = target.length ? scrolltargetPos : windowHeight
      $body.toggleClass('scrolled', scrolltop >= _windowHeight );
    });
  }
  const media = window.matchMedia('(max-width: 750px)')
  const trigger = new ScrollTrigger({
    trigger: {
      once: true,
      offset: {
        element: {
          x: 0,
          y: (trigger, rect, direction) => {
            return 0
          }
        },
        viewport: {
          x: 0,
          y: (trigger, frame, direction) => {
            return media.matches ? 0 : 0.4
          }
        }
      },
    },
  })
  trigger.add('[data-trigger]')


  // menu
  $('.js-toggle-menu').on('click', e => {
    $body.toggleClass('menu-active')
  })


  $('.js_check_empty').each((index, el) => {
    const $error = $(el).find('.js_error')
    const $input = $(el).find('input')
    $input.on('blur', e => {
      $input.val() ? $error.hide() : $error.show()
    })
  })






  // smooth scroll ------------------------------------------------------------
  function scrollTo(top){
    $('html,body').animate({ scrollTop: top }, {'easing': 'linear', 'duration': 500});
  }
  function calcScrollTo($el){
    // const offset = $('#nav').outerHeight() || 100;
    // const offset = $('.header').height();
    const offset = window.matchMedia('(min-width: 1200px)').matches ? 70 : $('.header').height()
    if($el.length){
      var to = $el.offset().top
    }else{
      var to = 0;
    }
    return to - offset;
  }
  $('a[href ^= "#"]').on('click', function(e){
    const $this = $(this);
    const id = $this.attr('href');
    if(id === '#') return false;
    const $el = $(id);
    scrollTo(calcScrollTo($el));

    if($this.hasClass('nohash')){
      e.preventDefault();
    }
    e.stopPropagation();
  });

  $('.js-popup-close').on('click', e => {
    $('.js-popup').fadeOut()
  })

  
});
